import Consts from '@wix/photography-client-lib/dist/src/utils/consts';
import { cssScrollHelper } from 'pro-gallery/dist/src/components/helpers/cssScrollHelper';
import window from '@wix/photography-client-lib/dist/src/sdk/windowWrapper';

export default class FullscreenHelper {
  constructor(galleryWrapper, props, isStore) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;
    this.isStore = isStore;

    this.update = this.update.bind(this);
    this.fullscreenItemsProps = this.fullscreenItemsProps.bind(this);
    this.updateFullscreenCurrentItem = this.updateFullscreenCurrentItem.bind(
      this,
    );
    this.toggleBrowserFullscreen = this.toggleBrowserFullscreen.bind(this);
    this.getFullscreenIndex = this.getFullscreenIndex.bind(this);
    this.animatedOpenFullscreen = this.animatedOpenFullscreen.bind(this);
    this.animatedCloseFullscreen = this.animatedCloseFullscreen.bind(this);
  }

  update(props) {
    this.galleryWrapperProps = props;
  }

  fullscreenItemsProps = () => {
    let items, totalItemsCount;
    if (
      this.galleryWrapper.state.fullscreen.directFullscreenItem &&
      this.galleryWrapper.state.fullscreen.directFullscreenItem.itemId
    ) {
      items = [this.galleryWrapper.state.fullscreen.directFullscreenItem];
      totalItemsCount = 1;
      return {
        items,
        totalItemsCount,
      };
    } else {
      return this.galleryWrapper.itemsHelper.pgItemsProps();
    }
  };

  updateFullscreenCurrentItem(item) {
    if (this.galleryWrapperProps.isInSEO && item) {
      if (typeof this.galleryWrapperProps.setMetaTags === 'function') {
        this.galleryWrapperProps.setMetaTags(item);
      }
    }
  }

  toggleBrowserFullscreen() {
    if (
      !window.document.fullscreenElement &&
      !window.document.mozFullScreenElement &&
      !window.document.webkitFullscreenElement &&
      !window.document.msFullscreenElement
    ) {
      // current working methods
      if (window.document.documentElement.requestFullscreen) {
        window.document.documentElement.requestFullscreen();
      } else if (window.document.documentElement.msRequestFullscreen) {
        window.document.documentElement.msRequestFullscreen();
      } else if (window.document.documentElement.mozRequestFullScreen) {
        window.document.documentElement.mozRequestFullScreen();
      } else if (window.document.documentElement.webkitRequestFullscreen) {
        window.document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        );
      }
    } else {
      if (window.document.exitFullscreen) {
        window.document.exitFullscreen();
      } else if (window.document.msExitFullscreen) {
        window.document.msExitFullscreen();
      } else if (window.document.mozCancelFullScreen) {
        window.document.mozCancelFullScreen();
      } else if (window.document.webkitExitFullscreen) {
        window.document.webkitExitFullscreen();
      }
    }
  }

  getFullscreenIndex(itemProps) {
    const { totalItemsCount } = this.galleryWrapper.itemsHelper.pgItemsProps();
    if (itemProps.idx > 0 && totalItemsCount > 0) {
      return itemProps.idx % totalItemsCount;
    }
    return itemProps.idx;
  }

  setGalleryWrapperFullscreenState(fullscreen, callback) {
    if (this.galleryWrapper.FullscreenElement) {
      this.galleryWrapper.setState({ fullscreen }, callback);
    } else {
      const appName = this.isStore ? 'ArtStore' : 'ProGallery';
      import(
        `../../components/${appName}/FullscreenWrapper/FullscreenWrapper`
      ).then(ProFullscreenWrapper => {
        this.galleryWrapper.FullscreenElement = ProFullscreenWrapper.default;
        this.galleryWrapper.setState({ fullscreen }, callback);
      });
    }
  }

  animatedOpenFullscreen(itemProps) {
    const expandAnimation = Consts.expandAnimations.FADE_IN; // styleParams.expandAnimation

    if (expandAnimation === Consts.expandAnimations.FADE_IN) {
      const fullscreen = Object.assign(
        {},
        this.galleryWrapper.state.fullscreen,
        {
          fullscreenAnimating: true,
          clickedIdx: this.getFullscreenIndex(itemProps),
        },
      );
      this.setGalleryWrapperFullscreenState(fullscreen, () => {
        const _fullscreen = Object.assign(
          {},
          this.galleryWrapper.state.fullscreen,
          {
            fullscreenAnimating: false,
          },
        );
        this.setGalleryWrapperFullscreenState(_fullscreen);
      });
    } else {
      const fullscreen = Object.assign(
        {},
        this.galleryWrapper.state.fullscreen,
        {
          fullscreenAnimating: false,
          clickedIdx: this.getFullscreenIndex(itemProps),
        },
      );
      this.setGalleryWrapperFullscreenState(fullscreen);
    }
  }

  animatedCloseFullscreen(itemIdx, animationDuration = 800) {
    //scroll to the item
    let y;
    try {
      if (itemIdx >= 0) {
        const itemDto = this.galleryWrapper.itemsHelper.pgItemsProps().items[
          itemIdx
        ];
        const item = { id: itemDto.itemId };
        const itemDomId = cssScrollHelper.getDomId(item);
        const itemContainer = window.document.getElementById(itemDomId);
        const rect = itemContainer.getBoundingClientRect();
        const padding = (window.innerHeight - rect.height) / 2;
        y = window.scrollY + rect.y - padding;
        if (y >= 0) {
          this.galleryWrapperProps.scrollTo(0, y);
        }
        itemContainer.focus();
      }
    } catch (e) {
      console.warn('Could find last fullscreen item', itemIdx, e);
    }

    const fullscreen = Object.assign({}, this.galleryWrapper.state.fullscreen, {
      fullscreenAnimating: true,
    });

    this.setGalleryWrapperFullscreenState(fullscreen, () => {
      setTimeout(() => {
        const _fullscreen = Object.assign(
          {},
          this.galleryWrapper.state.fullscreen,
          {
            directFullscreenItem: undefined,
            clickedIdx: -1,
          },
        );
        this.setGalleryWrapperFullscreenState(_fullscreen);
      }, animationDuration);
    });
  }
}
